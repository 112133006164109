<template>
	<div class="page">
		<el-form class="form" label-width="70px">

			<div class="big_tit" style="margin:0">
				<div>APP用户部分</div>
				<div style="margin-left:10px">{{app_user.real_status_text}}</div>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户手机" style="width:340px">
					<el-input class="el_inner_width" v-model="app_user.tel" :disabled="app_user.has_ser" clearable>
						<el-button type="success" slot="append" @click="user_ser">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户姓名" style="width:300px">
					<el-input class="el_inner_width" v-model="app_user.name" :disabled="app_user.real_status==2" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="身份证号" style="width:320px">
					<el-input class="el_inner_width" v-model="app_user.id_card_num" :disabled="app_user.real_status==2" clearable>
						<el-button type="success" slot="append" @click="app_user_real_sub">实名</el-button>
					</el-input>
				</el-form-item>
			</div>

			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:32%">
					<!-- <div class="img_btn" >
						<el-upload
							drag
							:before-upload="( file, ) =>handleAvatarSuccess( file,'app_user','headimg') "
							action=''
							multiple>
							  <div class="btn"  v-if="!app_user.headimg.src">未上传</div>
							  <img v-else :src="app_user.headimg.src" class="img">
						</el-upload>
						
					</div>
                    <div class="bottom_text" slot="tip">头像</div> -->
					<div class="img_btn" @click="choose_img('app_user','headimg')">
						<div v-if="!app_user.headimg.src" class="btn">未上传</div>
						<img v-else :src="app_user.headimg.src" class="img">
					</div>
					<div class="bottom_text">头像</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn" >
						<el-upload
							drag
							:before-upload="( file, ) =>handleAvatarSuccess( file,'app_user','id_card_img1') "
							action='#'
							multiple>
							  <div class="btn"  v-if="!app_user.id_card_img1.src">未上传</div>
							  <img v-else :src="app_user.id_card_img1.src" class="img">
						</el-upload>
						
					</div>
                    <div class="bottom_text" slot="tip">身份证正面</div>
<!-- 
					<div class="img_btn" @click="choose_img()">
						<div v-if="!app_user.id_card_img1.src" class="btn">未上传</div>
						<img  v-else :src="app_user.id_card_img1.src" class="img">
					</div>
					<div class="bottom_text">身份证正面</div> -->
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">

					<div class="img_btn" >
						<el-upload
							drag
							:before-upload="( file, ) =>handleAvatarSuccess( file,'app_user','id_card_img2') "
							action
							multiple>
							  <div class="btn"  v-if="!app_user.id_card_img2.src">未上传</div>
							  <img v-else :src="app_user.id_card_img2.src" class="img">
						</el-upload>
						
					</div>
                    <div class="bottom_text" slot="tip">身份证反面</div>
					<!-- <div class="img_btn" @click="choose_img('app_user','id_card_img2')">
						<div v-if="!app_user.id_card_img2.src" class="btn">未上传</div>
						<img v-else :src="app_user.id_card_img2.src" class="img">
					</div>
					<div class="bottom_text">身份证反面</div> -->
				</div>
			</div>


			<div class="big_tit">
				<div>司机证件</div>
				<div style="margin-left:10px">{{driver_info.status_text}}</div>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:49.5%">
					<div class="tr_img_btn" >
						<el-upload
							drag
							:before-upload="( file, ) =>handleAvatarSuccess( file,'driver_info','dl_img') "
							action
							multiple>
							  <div class="btn"  v-if="!driver_info.dl_img.src">未上传</div>
							  <img v-else :src="driver_info.dl_img.src" class="img">
						</el-upload>
						
					</div>
                    <div class="bottom_text" slot="tip">驾驶证</div>

					<!-- <div class="img_btn" @click="choose_img('driver_info','dl_img')">
						<div v-if="!driver_info.dl_img.src" class="btn">未上传</div>
						<img v-else :src="driver_info.dl_img.src" class="img">
					</div>
					<div class="bottom_text">驾驶证</div> -->
				</div>
				<div class="el_form_item" style="width:49.5%;margin-left:1%">
					<div class="tr_img_btn" >
						<el-upload
							drag
							:before-upload="( file, ) =>handleAvatarSuccess( file,'driver_info','cy_img') "
							action
							multiple>
							  <div class="btn"  v-if="!driver_info.cy_img.src">未上传</div>
							  <img v-else :src="driver_info.cy_img.src" class="img">
						</el-upload>
						
					</div>
                    <div class="bottom_text" slot="tip">从业资格证</div>
					<!-- <div class="img_btn" @click="choose_img('driver_info','cy_img')">
						<div v-if="!driver_info.cy_img.src" class="btn">未上传</div>
						<img v-else :src="driver_info.cy_img.src" class="img">
					</div>
					<div class="bottom_text">从业资格证</div> -->
				</div>
			</div>


			<div class="big_tit">
				<div>货车证件</div>
				<div style="margin-left:10px">{{truck_info.status_text}}</div>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车牌号" style="width:340px">
					<el-input class="el_inner_width" v-model="truck_info.truck_plate_num" :disabled="truck_info.status==2">
						<el-button type="success" slot="append" @click="truck_plate_num_edit">修改</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:49.5%">
					<div class="tr_img_btn" >
						<el-upload
							drag
							:before-upload="( file, ) =>handleAvatarSuccess( file,'truck_info','dl_img') "
							action
							multiple>
							  <div class="btn"  v-if="!truck_info.dl_img.src">未上传</div>
							  <img v-else :src="truck_info.dl_img.src" class="img">
						</el-upload>
						
					</div>
                    <div class="bottom_text" slot="tip">行驶证</div>
					<!-- <div class="img_btn" @click="choose_img('truck_info','dl_img')">
						<div v-if="!truck_info.dl_img.src" class="btn">未上传</div>
						<img v-else :src="truck_info.dl_img.src" class="img">
					</div>
					<div class="bottom_text">行驶证</div> -->
				</div>
				<div class="el_form_item" style="width:49.5%;margin-left:1%">
					<div class="tr_img_btn" >
						<el-upload
							drag
							:before-upload="( file, ) =>handleAvatarSuccess( file,'truck_info','tl_img') "
							action
							multiple>
							  <div class="btn"  v-if="!truck_info.tl_img.src">未上传</div>
							  <img v-else :src="truck_info.tl_img.src" class="img">
						</el-upload>
						
					</div>
                    <div class="bottom_text" slot="tip">道路运输证</div>
					<!-- <div class="img_btn" @click="choose_img('truck_info','tl_img')">
						<div v-if="!truck_info.tl_img.src" class="btn">未上传</div>
						<img v-else :src="truck_info.tl_img.src" class="img">
					</div>
					<div class="bottom_text">道路运输证</div> -->
				</div>
			</div>
			<div style="margin-top:20px;margin-right:20px;float:right">
				<el-form-item class="company_item">
					<el-button type="success" @click="ser_para_clear">清空</el-button>
				</el-form-item>
			</div>
		</el-form>
	</div>
</template>
<script>
	import {mapState} from 'vuex'
	export default {
		data() {
			return {

				//app用户部分
				app_user:{
					has_ser:false,
					tel:'',
					user_num:'',
					real_status:'',//1:未实名,2:审核通过,3:审核失败
					real_status_text:'未搜索',
					name:'',
					id_card_num:'',
					addr:'',
					headimg:{//头像
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'headimg'
					},
					id_card_img1:{//身份证正面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard1'
					},
					id_card_img2:{//身份证反面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard2'
					}
				},

				//司机
				driver_info:{
					status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					status_text:'未添加',
					dl_img:{//行驶证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'driverdl'
					},
					cy_img:{//从业资格证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'drivercy'
					},
				},

				//货车
				truck_info:{
					truck_inner_num:'',//货车编号,区别于车牌号,类似于id
					status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					status_text:'未添加',
					truck_plate_num:'',//车牌号
					dl_ocr_rel:{},
					dl_img:{//行驶证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'truckdl'
					},
					tl_img:{//道路运输证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'trucktl'
					},
				},
				
				//提交锁
				sub_swh:false
			}
		},
		created() {
			this.init();
			
		},
		mounted(){
			// this.multipleDragEvent()
		},
		methods: {
			//图片上传
			handleAvatarSuccess(file,type,img_name) {
				if(!this.app_user.has_ser){//未搜索不能修改
					return;
				}
				// if(type=='app_user' && this.app_user.real_status==2){//用户相关
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:'用户已实名,不能修改'
				// 	});
				// 	return;
				// }

				if(type=='driver_info'){//司机相关

					if(this.driver_info.status=='0'){
						this.$my.other.msg({
							type:'warning',
							str:'司机数据初始化未完成,请稍等,或者刷新重试'
						});
						return;
					}

					if(this.driver_info.status=='2'){
						this.$my.other.msg({
							type:'warning',
							str:'司机数据已审核通过,不能修改'
						});
						return;
					}
				}

				//货车相关
				if(type=='truck_info'){
					
					// if(this.truck_info.status=='0'){
					// 	this.$my.other.msg({
					// 		type:'warning',
					// 		str:'货车数据初始化未完成,请稍等,或者刷新重试'
					// 	});
					// 	return;
					// }

					if(this.truck_info.status=='2'){
						this.$my.other.msg({
							type:'warning',
							str:'货车数据已审核通过,不能修改'
						});
						return;
					}
				}
				let res={
					name:file.name,
					size:file.size,
					type:file.type,
				};
				if(window.createObjectURL!=undefined){ // basic
					res.src = window.createObjectURL(file);
				}else if(window.URL!=undefined){ // mozilla(firefox)
					res.src=window.URL.createObjectURL(file) ;
				}else if(window.webkitURL!=undefined){ // webkit or chrome
					res.src=window.webkitURL.createObjectURL(file) ;
				}
				//取出相应对象
				let ctr_obj=this[type][img_name]
				ctr_obj.src=res.src;
				ctr_obj.is_choose=true;
				ctr_obj.is_upl=false;
				this.$my.qiniu.files_cache[res.src] = file
				console.log(this.$my.qiniu.files_cache,1010)
				//上传图片
				this.$my.qiniu.upl({
					bucket:ctr_obj.bucket,
					key:ctr_obj.key,
					file_path:ctr_obj.src,
					callback:(data)=>{
						console.log(data)
						ctr_obj.is_upl=true;
						ctr_obj.key=data.key;
						this.save_img(type,img_name);
					}
				});
            },
			//清空
			ser_para_clear(){
								//app用户部分
				this.app_user={
					has_ser:false,
					tel:'',
					user_num:'',
					real_status:'',//1:未实名,2:审核通过,3:审核失败
					real_status_text:'未搜索',
					name:'',
					id_card_num:'',
					addr:'',
					headimg:{//头像
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'headimg'
					},
					id_card_img1:{//身份证正面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard1'
					},
					id_card_img2:{//身份证反面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard2'
					}
				},

				//司机
				this.driver_info={
					status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					status_text:'未添加',
					dl_img:{//行驶证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'driverdl'
					},
					cy_img:{//从业资格证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'drivercy'
					},
				},

				//货车
				this.truck_info={
					truck_inner_num:'',//货车编号,区别于车牌号,类似于id
					status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					status_text:'未添加',
					truck_plate_num:'',//车牌号
					dl_ocr_rel:{},
					dl_img:{//行驶证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'truckdl'
					},
					tl_img:{//道路运输证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'trucktl'
					},
				}
			},
			//修改车牌号
			truck_plate_num_edit(){

				if(!this.app_user.has_ser){//未搜索不能修改
					return;
				}

				if(this.truck_info.status==2){//用户相关
					this.$my.other.msg({
						type:'warning',
						str:'货车审核已通过,不能修改'
					});
					return;
				}

				if(!this.$my.check.is_plate_num(this.truck_info.truck_plate_num)){//车牌号格式
					this.$my.other.msg({
						type:'warning',
						str:'车牌号格式有误'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_edit_by_admin',
						truck_inner_num:this.truck_info.truck_inner_num,
						truck_plate_num:this.truck_info.truck_plate_num,
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'保存'
						});
					}
				});
			},

			//提交实名信息
			app_user_real_sub(){

				if(!this.app_user.has_ser){//未搜索不能修改
					return;
				}

				if(this.app_user.real_status==2){//用户相关
					this.$my.other.msg({
						type:'warning',
						str:'用户已实名,不能修改'
					});
					return;
				}

				if(!this.app_user.headimg.is_upl){//头像
					this.$my.other.msg({
						type:'warning',
						str:'头像未上传'
					});
					return;
				}

				if(!this.app_user.id_card_img1.is_upl){//身份证正面
					this.$my.other.msg({
						type:'warning',
						str:'身份证正面未上传'
					});
					return;
				}

				if(!this.app_user.id_card_img2.is_upl){//身份证反面
					this.$my.other.msg({
						type:'warning',
						str:'身份证反面未上传'
					});
					return;
				}

				//提交实名信息
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						is_real_check:1,//要求进行实名认证(需要同时传 姓名和身份证号)
						user_num:this.app_user.user_num,
						name:this.app_user.name,
						id_card_num:this.app_user.id_card_num,
						addr:this.app_user.addr,
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'实名成功'
						});
						this.app_user.real_status=2
					}
				});
			},

			//搜索
			user_ser(){

				if(this.app_user.has_ser){
					return;
				}

				if(!this.$my.check.is_tel(this.app_user.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'手机号码格式有误'
					});
					return;
				}

				//查找用户
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.app_user.tel
					},
					callback:(data)=>{

						//未注册
						if(data.list.length==0){
							
							//注册之
							this.$my.net.req({
								data:{
									mod:'app_user',
									ctr:'app_user_add_by_admin',
									tel:this.app_user.tel
								},
								callback:(data)=>{
									
									//已查找
									this.app_user.has_ser=true;

									//缓存用户数据
									this.app_user.user_num=data.user_num
									this.app_user.real_status=1
									this.app_user.real_status_text='未实名'
									this.app_user.name='未实名'

									//司机信息查找
									this.driver_ser();

									//货车信息查找
									this.truck_ser();
								}
							});
							return;
						}

						//已查找
						this.app_user.has_ser=true;

						//取出用户信息
						let user_info=data.list[0]

						//缓存用户数据
						this.app_user.user_num=user_info.user_num
						this.app_user.real_status=user_info.real_status
						switch(user_info.real_status){
							case '1':this.app_user.real_status_text='未实名';break;
							case '2':this.app_user.real_status_text='已实名';break;
							case '3':this.app_user.real_status_text='实名失败';break;
						}
						this.app_user.name=user_info.name
						this.app_user.id_card_num=user_info.id_card_num

						if(user_info.headimg){//头像
							this.app_user.headimg.is_upl=true
							this.app_user.headimg.key=user_info.headimg
							this.app_user.headimg.src=this.$my.qiniu.make_src(this.app_user.headimg.bucket,user_info.headimg)
						}

						if(user_info.id_card_img1){//身份证正面
							this.app_user.id_card_img1.is_upl=true
							this.app_user.id_card_img1.key=user_info.id_card_img1
							this.app_user.id_card_img1.src=this.$my.qiniu.make_src(this.app_user.id_card_img1.bucket,user_info.id_card_img1)
						}

						if(user_info.id_card_img2){//身份证反面
							this.app_user.id_card_img2.is_upl=true
							this.app_user.id_card_img2.key=user_info.id_card_img2
							this.app_user.id_card_img2.src=this.$my.qiniu.make_src(this.app_user.id_card_img2.bucket,user_info.id_card_img2)
						}

						//司机信息查找
						this.driver_ser();

						//货车信息查找
						this.truck_ser();
					}
				});
			},

			//司机信息查找
			driver_ser(){

				if(!this.app_user.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'司机信息查找:缺少用户编号'
					});
					return;
				}

				//查找
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_list_by_admin',
						user_num:this.app_user.user_num
					},
					callback:(data)=>{

						//未注册
						if(data.list.length==0){
							
							//注册之
							this.$my.net.req({
								data:{
									mod:'truck_driver',
									status:'2',//状态(1:审核中,2:审核通过,3:审核未通过)
									ctr:'driver_add_by_admin',
									user_num:this.app_user.user_num
								},
								callback:(data)=>{

									//添加绑定关系
									this.$my.net.req({
										data:{
											mod:'truck_driver',
											ctr:'driver_bind_add_by_admin',
											truck_owner_num:this.app_user.user_num,
											driver_num:this.app_user.user_num,
										},
										callback:(data)=>{
											
											//缓存司机数据
											this.driver_info.status='1'
											this.driver_info.status_text='审核中'
										}
									});
								}
							});
							return;
						}

						//取出司机信息
						let driver_info=data.list[0]

						//缓存司机数据
						this.driver_info.status=driver_info.status
						switch(driver_info.status){
							case '1':this.driver_info.status_text='审核中';break;
							case '2':this.driver_info.status_text='审核通过';break;
							case '3':this.driver_info.status_text='审核未通过';break;
						}
						
						if(driver_info.dl_img){//驾驶证
							this.driver_info.dl_img.key=driver_info.dl_img
							this.driver_info.dl_img.src=this.$my.qiniu.make_src(this.driver_info.dl_img.bucket,driver_info.dl_img)
						}

						if(driver_info.cy_img){//从业资格证
							this.driver_info.cy_img.key=driver_info.cy_img
							this.driver_info.cy_img.src=this.$my.qiniu.make_src(this.driver_info.cy_img.bucket,driver_info.cy_img)
						}
					}
				});
			},

			//货车信息查找
			truck_ser(){

				if(!this.app_user.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'司机信息查找:缺少用户编号'
					});
					return;
				}

				//查找
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_list_by_admin',
						user_num:this.app_user.user_num
					},
					callback:(data)=>{

						//未注册
						if(data.list.length==0){
							
							//注册之
							this.$my.net.req({
								data:{
									mod:'truck',
									ctr:'truck_add_by_admin',
									user_num:this.app_user.user_num
								},
								callback:(data)=>{
									
									//缓存司机数据
									this.truck_info.truck_inner_num=data.truck_inner_num
									this.truck_info.status='1'
									this.truck_info.status_text='审核中'
								}
							});
							return;
						}

						//取出货车信息
						let truck_info=data.list[0]

						//缓存货车数据
						this.truck_info.truck_inner_num=truck_info.truck_inner_num
						this.truck_info.status=truck_info.status
						switch(truck_info.status){
							case '1':this.truck_info.status_text='审核中';break;
							case '2':this.truck_info.status_text='审核通过';break;
							case '3':this.truck_info.status_text='审核未通过';break;
						}
						this.truck_info.truck_plate_num=truck_info.truck_plate_num
						
						if(truck_info.dl_img){//行驶证
							this.truck_info.dl_img.key=truck_info.dl_img
							this.truck_info.dl_img.src=this.$my.qiniu.make_src(this.truck_info.dl_img.bucket,truck_info.dl_img)
						}

						if(truck_info.tl_img){//道路运输证
							this.truck_info.tl_img.key=truck_info.tl_img
							this.truck_info.tl_img.src=this.$my.qiniu.make_src(this.truck_info.tl_img.bucket,truck_info.tl_img)
						}
					}
				});
			},

			//选择图片
			choose_img(type,img_name) {

				if(!this.app_user.has_ser){//未搜索不能修改
					return;
				}

				// if(type=='app_user' && this.app_user.real_status==2){//用户相关
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:'用户已实名,不能修改'
				// 	});
				// 	return;
				// }

				if(type=='driver_info'){//司机相关

					if(this.driver_info.status=='0'){
						this.$my.other.msg({
							type:'warning',
							str:'司机数据初始化未完成,请稍等,或者刷新重试'
						});
						return;
					}

					if(this.driver_info.status=='2'){
						this.$my.other.msg({
							type:'warning',
							str:'司机数据已审核通过,不能修改'
						});
						return;
					}
				}

				//货车相关
				if(type=='truck_info'){
					
					// if(this.truck_info.status=='0'){
					// 	this.$my.other.msg({
					// 		type:'warning',
					// 		str:'货车数据初始化未完成,请稍等,或者刷新重试'
					// 	});
					// 	return;
					// }

					if(this.truck_info.status=='2'){
						this.$my.other.msg({
							type:'warning',
							str:'货车数据已审核通过,不能修改'
						});
						return;
					}
				}

				//取出相应对象
				let ctr_obj=this[type][img_name]
				//选择图片
				this.$my.qiniu.choose_img({
					success:(res)=>{
						ctr_obj.src=res.src;
						ctr_obj.is_choose=true;
						ctr_obj.is_upl=false;

						//上传图片
						this.$my.qiniu.upl({
							bucket:ctr_obj.bucket,
							key:ctr_obj.key,
							file_path:ctr_obj.src,
							callback:(data)=>{
								console.log(data)
								ctr_obj.is_upl=true;
								ctr_obj.key=data.key;
								this.save_img(type,img_name);
							}
						});
					}
				});
			},
			save_img(type,img_name){
				console.log(1)
				/* 保存图片数据 */
				if(type=='driver_info'){

					//构造表单
					let form={
						mod:'truck_driver',
						ctr:'driver_creat_by_admin',
						user_num:this.app_user.user_num,
					};
					form[img_name]=this[type][img_name].key

					//发送
					this.$my.net.req({
						data:form,
						callback:(data)=>{
							this.$my.other.msg({
								type:'success',
								str:'司机证件保存成功'
							});
							return;
						}
					});

				}else if(type=='truck_info'){//货车部分
					
					//构造表单
					let form={
						mod:'truck',
						ctr:'truck_edit_by_admin',
						truck_inner_num:this[type].truck_inner_num,
					};
					form[img_name]=this[type][img_name].key

					//发送
					this.$my.net.req({
						data:form,
						callback:(data)=>{

							this.$my.other.msg({
								type:'success',
								str:'货车证件保存成功'
							});

							//识别行驶证
							if(img_name=='dl_img'){

								this.$my.other.msg({
									type:'success',
									str:'正在识别行驶证信息,请稍后'
								});

								this.$my.net.req({
									data:{
										mod:'res',
										ctr:'img_ocr',
										img_type:'driving_license',
										img_url:this.$my.qiniu.make_src(this[type][img_name].bucket,this[type][img_name].key)
									},
									callback:(data)=>{
										this[type].truck_plate_num=data.truck_plate_num
										this[type].dl_ocr_rel=data

										this.$my.other.msg({
											type:'success',
											str:'识别行驶证成功,正在保存行驶证识别结果'
										});

										//保存ocr结果
										this.$my.net.req({
											data:{
												mod:'truck',
												ctr:'truck_edit_by_admin',
												truck_inner_num:this[type].truck_inner_num,
												truck_plate_num:data.truck_plate_num,
												dl_ocr_rel:data.dl_ocr_rel
											},
											callback:(data)=>{
												this.$my.other.msg({
													type:'success',
													str:'行驶证识别结果保存成功'
												});
												return;
											}
										});
									}
								});
							}
						}
					});

				}else if(type=='app_user'){//用户部分

					//构造表单
					let form={
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						user_num:this.app_user.user_num,
					};
					form[img_name]=this[type][img_name].key

					//发送
					this.$my.net.req({
						data:form,
						callback:(data)=>{

							this.$my.other.msg({
								type:'success',
								str:'APP用户图片保存成功'
							});

							//识别身份证
							if(img_name=='id_card_img1'){

								this.$my.other.msg({
									type:'success',
									str:'正在识别身份证信息,请稍后'
								});

								this.$my.net.req({
									data:{
										mod:'res',
										ctr:'img_ocr',
										img_type:'id_card',
										img_url:this.$my.qiniu.make_src(this[type][img_name].bucket,this[type][img_name].key)
									},
									callback:(data)=>{
										this[type].name=data.name
										this[type].id_card_num=data.id_card_num
										this[type].addr=data.address

										//询问是否保存姓名和身份证哈
										this.$my.other.confirm({
											content:`身份证信息识别成功,点击'确定'进行实名认证`,
											confirm:()=>{

												//提交实名信息
												this.$my.net.req({
													data:{
														mod:'app_user',
														ctr:'app_user_edit_by_admin',
														is_real_check:1,//要求进行实名认证(需要同时传 姓名和身份证号)
														user_num:this[type].user_num,
														name:data.name,
														id_card_num:data.id_card_num,
														addr:data.addr,
													},
													callback:(data)=>{
														this.$my.other.msg({
															type:'success',
															str:'实名成功'
														});
														return;
													}
												});
											}
										});
									}
								});
							}
						}
					});
				}
			},

			//初始化
			init(){

				//app用户部分
				this.app_user={
					has_ser:false,
					tel:'',
					user_num:'',
					real_status:'',//1:未实名,2:审核通过,3:审核失败
					real_status_text:'未搜索',
					name:'',
					id_card_num:'',
					addr:'',
					headimg:{//头像
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'headimg'
					},
					id_card_img1:{//身份证正面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard1'
					},
					id_card_img2:{//身份证反面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard2'
					}
				},

				//司机
				this.driver_info={
					id:'',//司机表的id
					status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					status_text:'未添加',
					dl_img:{//行驶证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'driverdl'
					},
					cy_img:{//从业资格证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'drivercy'
					},
				},

				//货车
				this.truck_info={
					id:'',//货车表的id
					status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					status_text:'未添加',
					truck_plate_num:'',//车牌号
					dl_img:{//行驶证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'truckdl'
					},
					tl_img:{//道路运输证
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'trucktl'
					},
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
    
	.el_form_item{
		margin: 0;
		.tr_img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			/deep/ .el-upload-dragger {
				width: 450px !important;
				max-width:100% !important;
				height: 300px!important;
				border: none !important;
				// background-color: pink!important;
				display: block  !important;
				// margin:auto !important;
				/deep/.el-upload__text{
					margin: auto;
				}
			}
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}
			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			/deep/ .el-upload-dragger {
				// width: 100% !important;
				max-width:85% !important;
				height: 300px!important;
				border: none !important;
				// background-color: pink!important;
				display: block  !important;
				// margin:auto !important;
				/deep/.el-upload__text{
					margin: auto;
				}
			}
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}
			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		display: flex;
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 60px);
		margin-top:20px;
		padding:0 15px;
		overflow-y: auto;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>